<!-- <template src="./scanQR-BigBangBoom.html"></template> -->
<template src="./scanQR-socoBeautyStar.html"></template>

<script>
import QrcodeVue from 'qrcode.vue';
import SocketIO from 'socket.io-client';
import helpers from '@/mixins/helpers';
import autotimeout from '@/mixins/auto-timeout';

const QR_CODE_LIFE_TIME = 10 * 60 * 1000;
// const GET_TOKEN_INTERVAL = 5 * 1000;

const Config = require('~/config/default.env').default;

const socket = SocketIO(`${Config.MS_SOCKET_API_URL}/store`, { transports: ['websocket'] });

export default {
  name: 'home',
  components: {
    QrcodeVue,
  },
  mixins: [helpers, autotimeout],
  data() {
    return {
      quotes: null,
      qrGenTimer: null,
      getTokenTimer: null,
    };
  },
  computed: {
    is_development() {
      return Config.NODE_ENV === 'development';
    },
    QRcode() {
      const QRCodeValue = this.$store.state.QRcode.value || '';
      return QRCodeValue;

      // if (this.is_development) {
      //   return 'https://sociolla.test-app.link?action=login&value=' + QRCodeValue;
      // }

      // return 'https://sociolla.app.link?action=login&value=' + QRCodeValue;
    },
    isVnSource() {
      return this.$route.query.isvn;
    },
    nextUrl() {
      if (this.$route.query.isvn) {
        return '/vending-machine-vn/pimple-pop?isvn=true';
      } else {
        return this.$store.state.vm.urlVm ? this.$store.state.vm.urlVm : '/vending-machine/pimple-pop';
      }
    },
    beautyATMData() {
      return this.$store.state.beautyAtmData || null;
    },
  },
  async created() {
    Cookies.set('token', '', { expires: 0 });

    await this.$store.dispatch('getBeautyAtmData');
    let is_active_in_offline_store = true;

    if (this.beautyATMData && 'is_active_in_offline_store' in this.beautyATMData) {
      is_active_in_offline_store = Boolean(this.beautyATMData.is_active_in_offline_store);

      if (!is_active_in_offline_store) {
        return;
      }
    }

    this.$store.dispatch('generateQrCode');

    // re-generate qr code every 10 minutes
    this.qrGenTimer = setInterval(() => {
      if (this.$store.state.QRcode.isLoggedIn) {
        // stop re-generation after logging in
        return clearInterval(this.qrGenTimer);
      }
      this.$store.dispatch('generateQrCode');
    }, QR_CODE_LIFE_TIME);

    const self = this;

    socket.on('qr_code_login', function (data) {
      if (self.$store.state.QRcode.value == data.qr_code) {
        Cookies.set('token', data.token, { expires: 1 });
        self.$store.commit('authToken', data.token);
        self.$store.dispatch('getToken', { redirect: self.nextUrl, data: self });
        socket.off('qr_code_login');
      }
    });
  },

  destroyed() {
    clearInterval(this.qrGenTimer);
  },
};
</script>

<style lang="scss" scoped>
@import 'scanQR';

.bbb-qr {
  // background: #000 url('/static/img/big-bang-boom/bg-bbb-store.png') no-repeat center center;
  background: #000 url('/static/img/soco-beauty-star/bg-sbs-store.png') no-repeat center center;
  background-size: cover;
  min-height: calc(100vh - 0px);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border;
  padding: 0 0 170px;
  text-align: center;
}
.title {
  // margin: 0 auto 100px;
  margin: 0 auto 50px;
  display: block;
  max-width: 110%;
}
.teaser {
  // margin: 0 auto 24px;
  margin: 0 auto 20px;
  display: block;
}
.scan-qr-machines {
  width: 340px;
  height: 340px;
  background: #fff;
  box-shadow: 3px 12px 12px rgba(48, 0, 10, 0.5);
  border-radius: 7px;
  margin: 0 auto 100px;
  font: normal 30px/42px 'brandontext-bold', sans-serif;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.scan-qr-machines div {
  margin: 18px 0 4px;
}
.back {
  font: normal 48px 'brandontext-bold', sans-serif;
  color: #fff;
  margin: 24px 0 0;
  border-bottom: solid 2px #fff;
}
.scan-footers {
  background-color: #b32656;
  padding: 55px 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.scan-footers p {
  font-family: 'brandontext-bold', sans-serif;
  font-size: 36px;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.scan-qr-text {
  font-family: 'brandontext-bold', sans-serif;
  color: #b32656;
}

.scan-qr-sbs {
  width: 320px;
  height: 430px;
  background: #fff;
  box-shadow: 3px 12px 12px rgba(48, 0, 10, 0.5);
  border-radius: 20px;
  margin: 0 auto 100px;
  font: normal 38px/42px 'brandontext-bold', sans-serif;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.scan-qr-sbs div {
  margin: 15px 0 4px;
}

.sbs-text-title {
  color: #b32656;
  font: normal 56px/62px 'brandontext-bold', sans-serif;
}

.sbs-footers {
  background-color: #b32656;
  // padding: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 16%;
  border-radius: 60px 60px 0 0;
}
</style>
